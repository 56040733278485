.ui.grid>.row.visitHeader {
    /*background:#dddddd;*/
    background: rgba(255,149,0,0.50);
    font-family: 'Nunito Sans', sans-serif;    
}

.ui.grid>.row.visitDetailsRow {
    padding-block-end: 0.33em; 
}

.visitDetailsData {
    font-size: 1em;
    font-weight: 600;
    display: inline;

}

.visitDetailsSubData {
    font-size: 0.85em;
    display: inline;
    color: #444444;
}

a.VisitNameLink {
    text-decoration: underline;
    color: inherit;
}

.ui.grid>.row>.column.productColumn {
    padding-left: 1px;
    padding-right: 1px;
}

.productText {
    font-size: 0.85em;
    color: #444444;
}

.ui.image.productImage {
    height: 66%;
    object-fit: contain;
}