$border_radius: 10px;

@mixin sharp {
    .sharp {
        border-radius: 0px;
    }
}


.image-placeholder {
    position: static;
    overflow: hidden;
    animation: placeholderShimmer 2s linear;
    animation-iteration-count: infinite;
    background-color: #FFFFFF;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.08)), color-stop(15%, rgba(0, 0, 0, 0.15)), color-stop(30%, rgba(0, 0, 0, 0.08)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
    background-size: 1200px 100%;
    max-width: 30rem;
    border-radius: $border_radius;
    display: inline-block;

    +.image-placeholder {
        animation-delay: 0.15s;
        +.image-placeholder {
            animation-delay: 0.3s;
            +.image-placeholder {
                animation-delay: 0.45s;
                +.image-placeholder {
                    animation-delay: 0.6s;
                }
            }
        }
    }

    .image-box-holder {
        height: 100px;
        .aspect-ratio-base {
            height: 0px;
            overflow: hidden;
        }
        &.square {
            @extend .aspect-ratio-base;
            //[1/1] aspect ratio
            padding-top: 100%;
        }
        &.landscape {
            @extend .aspect-ratio-base;
            //[4/3] aspect ratio
            padding-top: 75%;
        }
        &.portrait {
            @extend .aspect-ratio-base;
            //[3/4] aspect ratio
            padding-top: 125%;
        }
    }


    @include sharp();
 }

@keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
