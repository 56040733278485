html {
    scroll-behavior: smooth;
}

.horizontal-list {
  white-space: nowrap;
  overflow-x: auto;
  width: 90%;
}

.horizontal-list .hl-item {
  display: inline;
}

.best-ident-face {
  border: 3px solid #00b248;
}

.frame-confidence {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 18px;
  margin: 10% 25% 0% 25% !important;
}

.sub-metadata{
  padding-top: 6px !important;
}

.scrollable-feedback {
  max-height: 350px;
  overflow-y: auto;
}

