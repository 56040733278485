.visitDatePicker {
    padding-right: 8px;
}

.visitHeader {
    background: '0xff9500';
}
.VisitNameLink {
    text-decoration: none !important;
}

.ui.horizontal.divider.visitsDivider {
    color: rgba(255, 149, 0, 0.85);
}