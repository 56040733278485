@mixin bold {
    &.bold {
        font-weight: 600;
        opacity: 0.6;
    }
}

@mixin limited {
    &.limited {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

.rich-paragraph {
    opacity: 0.7;
    color: #000000;
    font-family: "Be Vietnam";
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;

    .title {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 600;
        opacity: 0.9;
        line-height: inherit;
    }

    @include bold();
    //@include limited();
}