@mixin columns-count {
  &.one-col {
    grid-template-columns: repeat(1, auto);
  }
  &.two-col {
    grid-template-columns: repeat(2, auto);
  }
  &.three-col {
    grid-template-columns: repeat(3, auto);
  }
  &.four-col {
    grid-template-columns: repeat(4, auto);
  }
  &.five-col {
    grid-template-columns: repeat(5, auto);
  }
  &.six-col {
    grid-template-columns: repeat(6, auto);
  }
  &.seven-col {
    grid-template-columns: repeat(7, auto);
  }
  &.eight-col {
    grid-template-columns: repeat(8, auto);
  }
  &.nine-col {
    grid-template-columns: repeat(9, auto);
  }
  &.ten-col {
    grid-template-columns: repeat(10, auto);
  }
  &.eleven-col {
    grid-template-columns: repeat(11, auto);
  }
  &.twelve-col {
    grid-template-columns: repeat(12, auto);
  }
  &.thirteen-col {
    grid-template-columns: repeat(13, auto);
  }
  &.fourteen-col {
    grid-template-columns: repeat(14, auto);
  }
  &.fifteen-col {
    grid-template-columns: repeat(15, auto);
  }
  &.sixteen-col {
    grid-template-columns: repeat(16, auto);
  }
}

@mixin tight {
  &.tight {
    grid-gap: 0;
  }
}

@mixin padded {
  > .grid-box.padded{
    padding: 0.3em;
  }
}

@mixin alignment {
  > .grid-box.ltr{
    text-align: left;
  }
  > .grid-box.rtl{
    text-align: right;
  }
  > .grid-box.center{
    text-align: center;
  }
}

@mixin alternating {
  > .alternating.grid-box {
    background-color: #eee;
  }
  
  > .alternating.grid-box:nth-child(odd) {
    background-color: #ddd;
  }
}

.rich-grid {
  display: grid;
  gap: 0.7em;
  align-items: stretch;       //vertically
  justify-items: stretch;     //horizontally
  //grid-template-rows: 1fr 1fr; //Set via JavaScript


  @include columns-count();
  @include tight();
  @include padded();
  @include alignment();
  @include alternating()
}

.grid-header {
  margin-bottom: 0.6em;
  color: #000000;
  font-family: "Be Vietnam";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}