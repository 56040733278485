$card_accent: #000000;
$card_corner_radius: 15px;
$image_corner_radius: 24px;

@mixin side-panel {
    &.side-panel {
        grid-template-columns: 0.6fr 0.4fr;
        width: 100%;
    }
}

.card-wrapper {
    display: grid;
    grid-template-columns: auto;
    //column-gap: 20px;
    align-items: stretch;       //vertically
    justify-items: stretch;     //horizontally

    margin: 10px;
    border: 1px solid $card_accent;
    border-radius: $card_corner_radius;
    width: 60%;
    height: 737px;
    overflow: hidden;

    @include side-panel();
    .card-main {
        /*display: grid;
        grid-template-columns: auto;
        align-items: stretch;       //vertically
        justify-items: stretch;     //horizontally*/

        background-color: $card_accent;
        border-radius: $card_corner_radius;
        padding: 50px 1px 1px 1px;
        color: white;
        margin: -1px;
        .card-metadata {
            font-family: "Be Vietnam";
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            margin-top: -35px;
            padding: {
                bottom: 15px;
                left: 32px;
            }
        }
        .header-image {
            border-radius: $image_corner_radius;
            border: 4px solid $card_accent;
            width: 124px;
            height: 124px;
            margin-top: -60px;
            margin-bottom: 10px;
        }
        .card-body {
            background-color: white;
            color: black;
            border-radius: $card_corner_radius;
            margin: -1px;
            padding: 20px;
            height: 100%;
            border: 1px solid $card_accent;
            .content-wrapper h1, h2, h3, h4, h5, h6 {
                text-align: center;
            }
        }
    }
    .card-extended {
        padding: {
            top: 25px;
            bottom: 0px;
            left: 50px;
            right: 50px;
        }
        margin: {
            left: 15px;
            right: 15px;
        }
    }
}