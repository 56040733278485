@mixin font-size {
    .base-header-style {
        display: block;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    &.h1 {
        @extend .base-header-style;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
    }
    &.h2 {
        @extend .base-header-style;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }
    &.h3 {
        @extend .base-header-style;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    &.h4 {
        @extend .base-header-style;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
    }
    &.h5 {
        @extend .base-header-style;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
    }
    &.h6 {
        @extend .base-header-style;
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
    }
}

@mixin light {
    &.light {
        opacity: 0.7;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
    }
}

@mixin alignment {
    &.rtl {
        text-align: right;
    }
    &.ltr {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
}

@mixin emphasized-subheader {
    &.emphasized {
        font-size: 20px;
        opacity: 1;
        font-weight: 600;
    }
}

@mixin emphasized-wrapper {
    &.emphasize {
        font-size: 16px;
    }
}

@mixin vertical-align {
    &.top {
        margin-top: -10px;
        margin-bottom: 10px;
    }
    &.bottom {
        margin-top: 10px;
        margin-bottom: -10px;
    }
}

.rich-header {
	font-family: "Be Vietnam";
	font-size: 24px; //default
	font-weight: 600;
    line-height: 22px;
    .rich-subheader {
        margin-top: 5px;
	    opacity: 0.7;
	    color: #000000;
	    font-family: "Be Vietnam";
        font-size: 16px; //default
        font-weight: 400;
	    line-height: inherit;
        text-align: inherit;
        @include emphasized-subheader();
    }

    @include font-size();
    @include alignment();
    @include emphasized-wrapper();
    @include light();
    @include vertical-align();
}