
@mixin colors {
    &.ok {
        //background: #4caf50;
        background: #89FF00;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
    }
    &.problem {
        //background: #e51926;
        background-color: #F00;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #9e2c2c 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;        
        //animation: blinkProblem .7s infinite;
        @keyframes blinkProblem {
            from { 
                background-color: #F00;
            }
            50% { 
                background-color: #A00; 
                box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
            }
            to { 
                background-color: #F00; 
            }
        }
    }
    &.test {
        background: rgb(242, 149, 28);
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset rgb(155, 75, 22) 0 -1px 9px, #F2711C 0 2px 12px;
    }
    &.unknown {
        background: rgb(77, 77, 77);
        box-shadow: inset 0 0 2px #000000;
    }
}

.flare-wrapper {
    display: inline-block;
    .flare {
        width: 0.45em;
        height: 1.6em;
        border-radius: 0.45em;
        display: inline-block;
        vertical-align: bottom;
        @include colors();
    }
    .status {
        padding-left: 10px;
        vertical-align: baseline;
        h1, h2, h3, h4, h5, h6 {
            display: inline;
        }
    }
}