.slidecontainer {
  width: 100%;
}

.date-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: .1s;
}

.date-slider:hover {
  height: 7px;
  opacity: 1;
}

.date-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #F2711C;
  cursor: pointer;
  transition: .1s;
}

.date-slider::-webkit-slider-thumb:hover {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}