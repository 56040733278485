.ui.image.hidden {
  visibility: hidden;
}

.ui.image.thumbnail-landscape {
  /* object-fit: contain; */
  height: auto;
  width: 500px;
  max-height: 350px;
}

.ui.image.thumbnail-portrait {
  /* object-fit: contain; */
  height: 350px;
  width: auto;
  max-width: 500px;
}

.image-container {
  position: relative;
  display: inline-block;
  left: 0px;
  top: 0px;
}

.box {
  position: absolute;
  z-index: 2;
  border-radius: 5px;
  border: 2px solid #f2711c;
}