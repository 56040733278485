ul {
  list-style: none;
}

.ops-queue{
  position: fixed;
  z-index: 2000;
  overflow-y: auto;

/* For tablets and smartphones only: */
  max-height: 45%;
  bottom: 0%;
  right: 0%;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .ops-queue{
    max-height: 65%;
    bottom: 2%;
    right: 1%;
    width: 25%;
  }
}

/*For Operations Queue*/
.close-button{
  float: right;
  cursor: pointer;
}