$border_radius: 10px;
$transition_effect: .3s ease;
$partially_visible_opacity: 0.8;

$fluid_width: 100%;
$xxxl_width: 960px;
$xxl_width: 800px;
$xl_width: 600px;
$l_width: 450px;
$m_width: 300px;
$s_width: 150px;
$xs_width: 93px;
$xxs_width: 35px;
$xxxs_width: 15px;
//Prop styles:
@mixin sharp {
    .rich-image.sharp {
        border-radius: 0px;
    }
    .image-overlay.sharp {
        border-radius: 0px;
    }
    .static-overlay.sharp {
        border-radius: 0px;
    }
}

@mixin static {
    /*.rich-image.static{
        filter: blur(10px);
    }*/
    .static-overlay {
        .image-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: $border_radius;
            opacity: 0.8;
            transition: $transition_effect;
            background-color: rgb(22, 22, 22);
            .rich-image-description {
                color: white;
                font-size: 10px;
                font-family: "Be Vietnam";
                position: absolute;
                text-transform: uppercase;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }
}

@mixin sizes {
    &.fluid {
        width: $fluid_width;
    }
    &.xxx-large {
        width: $xxxl_width;
    }
    &.xx-large {
        width: $xxl_width;
    }
    &.x-large {
        width: $xl_width;
    }
    &.large {
        width: $l_width;
    }
    &.medium {
        width: $m_width;
    }
    &.small {
        width: $s_width;
    }
    &.x-small {
        width: $xs_width;
    }
    &.xx-small {
        width: $xxs_width;
    }
    &.xxx-small {
        width: $xxxs_width;
    }
}

@mixin inline {
    &.inline {
        margin: 0;
    }
}

.image-wrapper {
    position: relative;
    margin: 5px;
    .rich-image {
        border-radius: $border_radius;
        display: block;
        width: 93px;
        height: 139px;
        object-fit: cover;
    }
    .image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: $border_radius;
        opacity: 0;
        transition: $transition_effect;
        background-color: rgb(22, 22, 22);

        .rich-image-description {
            color: white;
            font-size: 10px;
            font-family: "Be Vietnam";
            position: absolute;
            text-transform: uppercase;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
    .static-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(240, 240, 240);
        border: 1px solid #b3b3b3;
        border-radius: $border_radius;
        opacity: 0.5;
        //filter: blur(0px);
        //filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask");
        
        //NOT to be confused with the HOVER description!!
        .rich-image-description {
            color: white;
            font-size: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            cursor: default;
        }
    }
    &:hover {
        .image-overlay {
            opacity: $partially_visible_opacity;
            cursor: default;
        }
    }
    //Props
    @include sharp();
    @include static();
    @include sizes();
    @include inline()
}
