.horizontal-list {
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
}

.stickey-table-header{
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  overflow: hidden;
}